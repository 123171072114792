<template>
  <div
    class="relative youtube-video-with-modal cursor-pointer"
    @click="isShowingModal = true"
    :style="`background-image: url('https://img.youtube.com/vi/${videoId}/hqdefault.jpg');`">
    <!--this padding is specific to the mc-play icon looking offset-->
    <icon-halo
      class="absolute-center z-level-1"
      style="padding-left:0.2em;"
      icon="mc-play"
      :size="iconSize"
      :halo-theme="iconHaloTheme"
      :border-theme="iconHaloBorderTheme"
      :border-thickness="iconHaloBorderThickness"
      :theme="iconHaloIconTheme"></icon-halo>

    <youtube-video-modal
      :show="isShowingModal"
      @modal:closed="hideModal"
      :video-id="videoId"
      :autoplay="autoPlay"
    >

    </youtube-video-modal>
  </div>
</template>

<script>
export default {
  props: {
    videoId: {
      type: String,
      default: "",
    },
    iconSize: {
      type: Number,
      default: 3,
    },
    iconHaloTheme: {
      type: String,
      default: "lead",
    },
    iconHaloIconTheme: {
      type: String,
      default: "bg",
    },
    iconHaloBorderTheme: {
      type: String,
      default: "transparent",
    },
    iconHaloBorderThickness: {
      type: Number,
      default: 0,
    },
    autoPlay: {
      type: Boolean,
      default: true,
    }
  },
  data: function () {
    return {
      isShowingModal: false,
    };
  },
  methods: {
    showModal() {
      this.isShowingModal = true;
    },
    hideModal() {
      this.isShowingModal = false;
    },
    closeModal() {
      return this.hideModal();
    }
  }
};
</script>

<style scoped lang="scss">
.youtube-video-with-modal {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
